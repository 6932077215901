// Global
import classnames from 'classnames';
import { iconSizeClasses } from '../ContentMangedIcon/ContentMangedIcon';
import { heroIconFactory } from 'temp/heroIcons';
import { HeroIconProps } from './HeroIcon';

const HeroIcon = ({ className, icon, mini, outline, size }: HeroIconProps): JSX.Element => {
  if (!icon) {
    return <></>;
  }

  const Icon = heroIconFactory(`${icon}_${mini ? '20' : '24'}_${outline ? 'outline' : 'solid'}`);

  return <Icon className={classnames(className, iconSizeClasses[size])} />;
};

export default HeroIcon;
